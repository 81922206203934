body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-pagination-list .MuiTablePagination-toolbar {
  padding-left: 2px;
}

.custom-pagination-list
  .MuiTablePagination-toolbar
  > .MuiTablePagination-spacer {
  flex: 0;
}

.custom-pagination-list div.MuiToolbar-root {
  justify-content: start;
}

.custom-pagination-list div.MuiToolbar-root > form {
  flex: none;
  -webkit-flex: none;
}
